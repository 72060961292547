
@font-face {
  font-family: 'Helvetica';
  src: url('fonts/Helvetica.eot');
  src: url('fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica.woff2') format('woff2'),
       url('fonts/Helvetica.woff') format('woff'),
       url('fonts/Helvetica.ttf') format('truetype'),
       url('fonts/Helvetica.svg#Helvetica') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('fonts/BebasNeueRegular.eot');
  src: url('fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
       url('fonts/BebasNeueRegular.woff2') format('woff2'),
       url('fonts/BebasNeueRegular.woff') format('woff'),
       url('fonts/BebasNeueRegular.ttf') format('truetype'),
       url('fonts/BebasNeueRegular.svg#BebasNeueRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}


body {
margin: 0;
/*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
font-family: 'Helvetica';
font-size: 1rem;
font-weight: normal;
font-style: normal;
line-height: 1.50;
color: #000000;
text-align: left;
background-color: #ffffff;
}

 .container-xl {
max-width: 100vw;
padding-left: 7vw;
padding-right: 7vw;
} 
.firstblock{
  margin-top: 7vh;
}
h1, h2, h3, h4, h5, h6 {
font-family: 'Bebas Neue';
font-weight: normal;
font-style: normal;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
font-family: 'Bebas Neue';
font-weight: normal;
font-style: normal;
}

p {
font-family: 'Helvetica';
font-weight: normal;
font-style: normal;
}
.heading-text {
letter-spacing: 0.41vw;
font-size: 2.9vw;
/*font-size: 3.2vw;
line-height: 6vh; */
font-weight: 600;
margin-bottom: 0;
}
.heading-cotact{
  letter-spacing: 0.41vw;
  font-size: 2.4vw !important;
  /* line-height: 6vh; */
  font-weight: 600;
  margin-bottom: 0;
}
.subtitle {
letter-spacing: 0.2vw;
font-size: 1.1vw;
/* line-height: 3.4vh; */
margin-bottom: 0;
}
.span-text {
font-family: 'Helvetica';
font-weight: normal;
font-style: normal;
}
.text-blue {
/*color: #4B6DB4;*/
color: #28A9E2;
}
.text-black {
  color: #666;
}
.body-text {
letter-spacing: 0.0525vw;
font-size: 1vw;
line-height: 2.7vh;
}

/* #Media
=========== */
@media (max-width: 767px) {
.heading-text {
  letter-spacing: 1.094vw;
  font-size: 9.156vw;
  line-height: 12.240vw;
  overflow-wrap: break-word;
}
.subtitle {
  letter-spacing: 0.466vw;
  font-size: 3.82vw;
  line-height: 4.167vw;
  margin-bottom: 1vw;
}
.body-text {
  letter-spacing: 0.210vw;
  font-size: 3.948vw;
  line-height: 5.469vw;
}
}

/*==== Nab CSS Start======*/

.start-header {
opacity: 1;
transform: translateY(0);
/*padding: 10px 0;
box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
-webkit-transition : all 0.3s ease-out;*/
transition : all 0.3s ease-out;
}
.start-header.scroll-on {
top: 0;
/*box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
padding: 10px 0;*/
-webkit-transition : all 0.0s ease-out;
transition : all 0.0s ease-out;
}
.start-header.scroll-on .navbar-brand img{
height: 5.67vh;
-webkit-transition : all 0.3s ease-out;
transition : all 0.3s ease-out;
}
.navigation-wrap{
position: fixed;
width: 100%;
top: 5.8vh;
padding-bottom: 5px;
left: 0;
z-index: 999;
-webkit-transition : all 0.3s ease-out;
transition : all 0.3s ease-out;
}
.navbar{
padding: 0;
}
.navbar-brand {
  padding-top: 1.6vh;
  padding-bottom: 1.2vh;
}
.navbar-brand img{
height: 5.26vh;
width: auto;
display: block;
-webkit-transition : all 0.3s ease-out;
transition : all 0.3s ease-out;
}
.navbar-toggler {
float: right;
border: none;
padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
outline: none;
}
.navbar-toggler-icon {
width: 32px;
height: 18px;
background-image: none;
position: relative;
border-bottom: 2px solid #FFFFFF;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before{
width: 32px;
position: absolute;
height: 2px;
background-color: #FFFFFF;
top: 0;
left: 0;
content: '';
z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after{
top: 8px;
}
/* .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
border-color: transparent;
} */
.is_open .navbar-toggler-icon:after {
  transform: rotate(45deg);
  }
  .is_open .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  }
  .is_open .navbar-toggler-icon {
  border-color: transparent;
  }
.nav-link{
color: #FFFFFF !important;
font-weight: 500;

letter-spacing: 0.139vw;
  transition: all 200ms linear;
text-transform: uppercase;
}
.nav-item:hover .nav-link{
color: #FFFFFF !important;
}
.nav-item.active .nav-link{
color: #FFFFFF !important;
}
.nav-link {
position: relative;
padding: 0.39vw 0.8vw !important;
display: inline-block;
}
.nav-item:after{
position: absolute;
bottom: 0px;
left: 0;
width: 100%;
height: 2px;
content: '';
/*background-color: #4B6DB4;*/
background-color: #28A9E2;
opacity: 0;
transition: all 200ms linear;
}
.nav-item:hover:after{
bottom: 0;
opacity: 1;
}
.nav-item.active:after{
opacity: 1;
}
.nav-item.active:hover:after{
opacity: 1;
}
.nav-item{
position: relative;
  transition: all 200ms linear;
}

.mail-txt {
margin-bottom: 0;
  list-style: none;
  padding: 0;
}
.mail-txt li {
display: inline-block;
padding-right: 1vw;
/*color: #707070;*/
font-weight: 500;
line-height: 2.1vw;
}
.mail-txt li a {
/*color: #707070;*/
color: #F1F1F3;
text-decoration: none;
}
.mail-txt li a:hover {
color: #09092D;
/*color: #4B6DB4;*/
}
.social-icons {
margin-bottom: 0;
  list-style: none;
  padding: 0;
}
.social-icons li {
display: inline-block;
padding-left: 1vw;
/*color: #0D1134;*/
color: #F1F1F3;
font-weight: 500;
font-size: 1.87vw;
}
.social-icons li a {
/*color: #0D1134;*/
color: #F1F1F3;
text-decoration: none;
}
.login-txt li a:hover {
color: #09092D;
}
.social-icons li:first-child {
padding-left: 0px;
}

.bg-dark {
  /*background-color: #0D1134 !important;*/
  background-color: #09092D !important;
}
.bg-light {
  background-color: #28A9E2 !important;
}

/* #Primary style
================================================== */

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
max-height: 0;
  display: block;
padding: 0;
margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
max-height: 999px;
  transform: translate3d(0, 0px, 0);
padding: 0.78125vw;
}
.dropdown-menu {
padding: 0.78125vw;
margin: 0;
font-size: 1.2vw;
letter-spacing: 0.0515vw;
color: #FFFFFF;
background-color: #0D1134;
border: none;
border-radius: 0.78125vw;
/*box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);*/
  transition: all 200ms linear;
}
.dropdown-toggle::after {
display: none;
}


.dropdown-sub-menu {
-webkit-columns: 2;
}
.dropdown-item {
padding: 0.45vh 1vw;
  color: #FFFFFF;
  margin: 0.78125vw 0;
  font-size: 1vw;
border-radius: 0.3vw;
  transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
color: #FFFFFF;
/*background-color: #4B6DB4;*/
background-color: #28A9E2;
}
.sm-none {
display: black;
}
.sm-black {
display: none;
}


/* #Media
================================================== */

@media (max-width: 767px) {
.navigation-wrap {
  top: 0px;
  padding: 0px;
  padding-bottom: 5px;
}
.navbar-brand img {
  /*height: 14.271vw;*/
  height: 8vw;
}
.start-header.scroll-on .navbar-brand img {
  height: 10.667vw;
}
.nav-item:after{
  display: none;
}
/*.nav-item::before {
  position: absolute;
  display: block;
  top: 15px;
  left: 0;
  width: 11px;
  height: 1px;
  content: "";
  border: none;
  background-color: #000;
  vertical-align: 0;
}
.dropdown-toggle::after {
  position: absolute;
  display: block;
  top: 10px;
  left: -23px;
  width: 1px;
  height: 11px;
  content: "";
  border: none;
  background-color: #000;
  vertical-align: 0;
  transition: all 200ms linear;
}
.dropdown-toggle[aria-expanded="true"]::after{
  transform: rotate(90deg);
  opacity: 0;
}*/
.navbar-collapse {
  /*background-color: #4B6DB4;*/
  background-color: #28A9E2;
  position: absolute;
  left: -8%;
  top: -10px;
  width: 118%;
  padding: 5.2vw 3.2vw;
}
.nav-link {
  font-size: 4.1vw;
  padding: 1.82vw 0.8vw !important;
  letter-spacing: 0.439vw;
}
.dropdown-menu {
  padding: 0 !important;
  background-color: transparent;
  box-shadow: none;
  transition: all 200ms linear;
}
.dropdown-item {
  padding: 0.45vh 6vw;
  margin: 0.78125vw 0;
  font-size: 3.9vw;
  letter-spacing: 0.4vw;
  border-radius: 0;
}
.nav-item.show .dropdown-menu {
  padding: 5px 0;
}
.dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}
.xs-txt {
  color: #FFFFFF;
}
.xs-txt:hover {
  color: #0D1134;
}

.social-icons {
  margin-top: 25px;
  margin-bottom: 10px;
}
.social-icons li {
  padding-left: 5vw;
  font-size: 6.47vw;
}
.social-icons li a {
  color: #FFFFFF;
  text-decoration: none;
}
.sm-none {
  display: none;
}
.sm-black {
  display: blacks;
}
}


/*============== Button Color CSS ======================*/
.btn {
  padding: 1.2vh 1.8vw;
  border-radius: 0.78125vw;

}

.btn-normal {
color: #FFF;
background-color: #28A9E2;
border-color: #28A9E2;
font-family: 'Helvetica';
font-style: normal;
font-weight: 500;
}

.btn-normal:hover {
color: #FFF;
background-color: #5CC1EB;
border-color: #5CC1EB;
}

.btn-normal:focus, .btn-normal.focus {
color: #FFF;
background-color: #4B6DB4;
border-color: #4B6DB4;
box-shadow: 0 0 0 0.2rem rgba(75, 109, 180, 0.5);
}

.btn-normal.disabled, .btn-normal:disabled {
color: #FFF;
background-color: #5780d4;
border-color: #5780d4;
}


.btn-blue {
color: #FFF;
background-color: #0D1134;
border-color: #0D1134;
font-family: 'Helvetica';
font-style: normal;
font-weight: 500;
}
.btn-blue-active{
  color: #FFF;
  background-color: #131a5b;
  border-color: #131a5b;
  font-family: 'Helvetica';
font-style: normal;
font-weight: 500;
  }
.btn-blue:hover,.btn-blue-active:hover {
color: #FFF;
background-color: #131a5b;
border-color: #131a5b;
}

.btn-blue:focus, .btn-blue.focus {
color: #FFF;
background-color: #0D1134;
border-color: #0D1134;
box-shadow: 0 0 0 0.2rem rgba(13, 17, 52, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
color: #FFF;
background-color: #131a5b;
border-color: #131a5b;
}
.small-icon {

  padding-top: 3px;
}

.form-control {
  height: calc(2em + 0.75rem + 2px);
  color: #000;
  border-radius: 10px;
}
.datepicker-dropdown {
width: 23%;
background-color: #0D1134;
}
.datepicker-dropdown table {
  width: 100%;
}

/* #Media
=========== */
@media (max-width: 767px) {
.btn {
  padding: 3vw 13.625vw;
  border-radius: 2.604vw;
  font-size: 4.5vw;
}
.small-icon {
  font-size: 4vw;
}
.datepicker-dropdown {
  width: 86%;
  background-color: #0D1134;
}
}




/*============== Banner CSS ======================*/
.banner-section {
padding-top: 10vh;
min-height: 80vh;
/*background-color: #0D1134;*/
background-color: #09092D;
background-image: url(assets/dist/images/banner-bg.jpg);
background-repeat: no-repeat;
background-size: cover;
background-position: center;
}
.banner-section img {
width: 85.15625vw;
/* height: 53vh; */
}
.banner-section .banner-txt {
margin-top: 10vh;
text-align: right;
}
.banner-section p.txt {
letter-spacing: 0.0525vw;
font-size: 1.2vw;
line-height: 3.3vh;
}
.banner-section .border-line {
border-right:  0.235vw solid #fff;
min-height: 10.27vh;
margin-bottom: 1.5vh;
}



/* #Media
=========== */

@media (max-width: 767px) {
.banner-section {
  padding-top: 20.667vw;
  padding-bottom: 0;
  min-height: 56vh;
}
.banner-section img {
  width: 87.15625vw;
  height: auto;
}
.banner-section .banner-txt {
  margin-top: 0px;
  text-align: center;
}
.banner-section p.txt {
  font-size: 3.646vw;
  line-height: 4.667vw;
  letter-spacing: 0.120vw;
}
.banner-section .border-line {
  border-right: 0px solid #fff;
  min-height: 7.813vw;
  margin-bottom: 0px;
}
}




/*============== Web Agency CSS ======================*/
.agency-section {
padding: 2.5vw 0;
/*background-color: #4B6DB4;*/
background-color: #28A9E2;
}
.agency-section h1,
.agency-section .h1 {
  font-size: 4px;
margin-bottom: 0;
}
.agency-section .heading-text {
  letter-spacing: 0.52vw;
  font-size: 25px;  /* 3vw; */
  line-height: 5.6vh;
}
.agency-section .subtitle {
  letter-spacing: 0.1vw;
  font-size: 1.1vw;
  /* line-height: 2.4vh; */
}
.agency-section p {
letter-spacing: 0.028vw;

margin-bottom: 1.4vh;
}
.agency-section .border-line {
border-right: 2px solid #fff;
min-height: 10.48vh;
vertical-align: middle;
line-height: 10.48vh;
}
.agency-section .border-line img {
width: 4.72vw;
}
.agency-section .border-line .col-md-8 {
padding-right: 0;
  padding-left: 0;
}


/* #Media
=========== */

@media (max-width: 767px) {
.agency-section {
  padding-top: 6.8vh;
  padding-bottom: 6.8vh;
}
.agency-section .container {
  padding-left: 10vw;
  padding-right: 10vw;
}
.agency-section .border-line {
  border-right: 0px solid #fff;
  min-height: 21.615vw;
  line-height: 21.615vw;
  margin-bottom: 12vw;
}
.agency-section .heading-text {
  letter-spacing: 1.5vw;
  font-size: 10.656vw;
  line-height: 11.24vw;
}
.agency-section .subtitle {
  letter-spacing: 0.46vw;
  font-size: 3.8vw;
  line-height: 4.167vw;
  margin-bottom: 1vw;
}
.agency-section p {
  letter-spacing: 0.18vw;
  font-size: 3.66vw;
  line-height: 5.2vw;
  margin-bottom: 4vh;
}
.agency-section .border-line img {
  width: 23.615vw;
}
}


/*============== Our Services CSS ======================*/
.service-section {
padding: 6.8vh 0 0;
background-color: #fff;
}
.service-box {
border: 1px solid #4B6DB4;
border-radius: 0.78vw;
color: #000000;
text-align: center;
padding: 2.34vw 1.17vw 1.17vw;
height:100%;
}
.service-box:hover {
border-radius: 0.78vw;
color: #000000;
text-align: center;
padding: 2.34vw 1.17vw 1.17vw;
box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
-webkit-box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
-moz-box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
}
.service-box img {
width: 3.6vw;
}
.service-box .subtitle {
  letter-spacing: 0.16vw;
  font-size: 1.1vw;
}
.service-box p.service-subtitle {
letter-spacing: 0.0525vw;
font-size: 1vw;
/* line-height: 2.2vh; */
min-height: 7.49vh;
}


/* #Media
=========== */

@media (max-width: 767px) {
.service-section {
  padding-top: 15.625vw;
  padding-bottom: 0;
}
.service-section .mb-5 {
  margin-bottom: 2rem !important;
}
.service-box {
  border: 1px solid #4B6DB4;
  border-radius: 2.604vw;
  padding: 10vw 7vw 7vw;
}
.service-box:hover {
  border-radius: 2.604vw;
  padding: 10vw 7vw 7vw;
  box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -webkit-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -moz-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
}
.service-box img {
  width: 14.063vw;
}
.service-box .subtitle {
  letter-spacing: 0.521vw;
  font-size: 4.688vw;
  line-height: 5.469vw;
}
.service-box p.service-subtitle {
  letter-spacing: 0.260vw;
  font-size: 4.688vw;
  line-height: 5.469vw;
  min-height: 7.49vh;
}
}




/*============== Web design & advertising agency CSS ======================*/
.web-designe-section {
padding: 6.8vh 0 0;
background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 200% 50%;
min-height: 700px;
}
@media (min-width: 992px)  and (max-width: 1023px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 190% 50%;
}
}
@media (min-width: 1024px)  and (max-width: 1120px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 175% 50%;
}
}
@media (min-width: 1124px)  and (max-width: 1280px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 120% 50%;
}
}
@media (min-width: 1281px)  and (max-width: 1360px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 115% 50%;
}
}
@media (min-width: 1361px)  and (max-width: 1480px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 107% 50%;
}
}
@media (min-width: 1481px)  and (max-width: 1560px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 105% 50%;
}
}
@media (min-width: 1561px)  and (max-width: 1680px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 96% 50%;
}
}
@media (min-width: 1681px)  and (max-width: 1919px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 90% 50%;
}
}
@media (min-width: 1920px) and (max-width: 2019px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 84% 50%;
}
}
@media (min-width: 2020px) and (max-width: 2120px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 76% 50%;
}
}
@media (min-width: 2121px) {
  .web-designe-section {
  background: #fff  url(assets/dist/images/web-designe-img1.png) no-repeat scroll 74% 50%;
}
}


.project-box img {
width: 3.6vw;
}
.web-designe-section .h2 {
letter-spacing: 0.59vw;
font-size: 3.4vw;
line-height: 6vh;
font-weight: 600;
margin-bottom: 0;
}
.web-designe-section .project-txt {
letter-spacing: 0.2vw;
  font-size: 1vw;
line-height: 3.4vh;
font-family: 'Helvetica';
position: relative;
}
.web-designe-section .project-txt:after {
position: absolute;
  bottom: -4px;
  left: 0;
  width: 60%;
  height: 2px;
  content: '';
  background-color: #4B6DB4;
}



/* #Media
=========== */
@media (max-width: 767px) {
.web-designe-section {
  padding: 15.625vw 0 0;
  margin-top: 46vw;
  background: #fff  url(assets/dist/images/mobile-web-designe-img1.png) no-repeat top left;
}
.web-designe-mobile {
  height: 106vw;
  background: #fff  url(assets/dist/images/mobile-web-designe-img1.png) no-repeat top left;
}
.web-designe-mobile-gap {
  height: 106vw;
}
.web-designe-section .heading-text {
  margin-top: -46vw;
}
.project-box {
  text-align: center;
  margin-bottom: 6vw;
}
.project-box img {
  width: 15.104vw;
}
.web-designe-section .h2 {
  letter-spacing: 1.094vw;
  font-size: 10.156vw;
  line-height: 12.240vw;
}
.web-designe-section .project-txt {
  letter-spacing: 0.210vw;
  font-size: 3.948vw;
  line-height: 5.469vw;
  margin: 0 auto;
  width: 190px;
  position: relative;
}
.web-designe-section .project-txt:after {
  bottom: -4px;
  left: 19%;
  width: 60%;
}
}



/*============== Blog CSS ======================*/
.common-section {
padding: 6.8vh 0 0;
background: #fff;
}
.post-item {
color: #000000;
text-align: left;
border-radius:  0.78vw;
position: relative;
}
.post-item:hover {
border-radius: 0.78vw;
box-shadow: 0.6vw 0.6vw 0.6vw 0.6vw rgba(0,0,0,0.1);
-webkit-box-shadow: 0.6vw 0.6vw 0.6vw 0.6vw rgba(0,0,0,0.1);
-moz-box-shadow: 0.6vw 0.6vw 0.6vw 0.6vw rgba(0,0,0,0.1);
}
.blog-txt {
position: absolute;
top: 5px;
right: 3px;
border: 1px solid #808080;
background-color: #fff;
color: #000;
padding: 4px 15px;
font-size: 16px;
border-radius: 6px;

}
.post-item img {
border: 0px solid #4B6DB4;
border-bottom: 0px solid transparent;
border-radius: 0.78vw 0.78vw 0 0;
}
.post-item .description {
border: 1px solid #4B6DB4;
border-top: 0px solid transparent;
border-radius: 0 0 0.78vw 0.78vw;
padding: 15px;
margin-bottom: 20px;
}
.post-item p.post-title {
letter-spacing: 0.14vw;
  font-size: 1.16vw;
  line-height: 2.4vh;
  margin-bottom: 0;
font-weight: 600;
margin-bottom: 1.2vh;
}
.post-item p.post-title a {
color: #000000;
text-decoration: none;
}
.post-item p {
letter-spacing: 0px;
font-size: 1.1vw;
line-height: 2vh;
color: #000000;
}
.post-item p a {
color: #000000;
text-decoration: none;
}
.post-item p.posted {
margin-bottom: 0px;
letter-spacing: 0px;
font-size: 1vw;
color: #808080;
}
.post-item p.posted a {
color: #808080;
text-decoration: none;
}
.owl-carousel {
width: 102%;
}
#blog-carousel .owl-carousel .owl-item img {
height: 18vh;
}

/* #Media
=========== */
@media (max-width: 767px) {
.common-section .mb-5 {
  margin-bottom: 1rem !important;
}
.post-item:hover {
  border-radius: 2.604vw;
  box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -webkit-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -moz-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
}
.blog-txt {
  padding: 1vw 3.906vw;
  font-size: 3.167vw;
  border-radius: 2.604vw;

}
.post-item img {
  border: 0px solid #4B6DB4;
  border-bottom: 0px solid transparent;
  border-radius: 2.604vw 2.604vw 0 0;
}
.post-item .description {
  border: 1px solid #4B6DB4;
  border-top: 0px solid transparent;
  border-radius: 0 0 2.604vw 2.604vw;
  padding: 4vw;
  margin-bottom: 5.3vw;
}
.post-item p.post-title {
  letter-spacing: 0.466vw;
  font-size: 4.92vw;
  line-height: 6.167vw;
  margin-bottom: 2.2vh;
}
.post-item p {
  letter-spacing: 0.210vw;
  font-size: 3.948vw;
  line-height: 5.469vw;
}
.post-item p.posted {
  font-size: 3vw;
}
#blog-carousel .owl-carousel .owl-item img {
  height: 24vh;
}
}



/*============== Projects Filter CSS ======================*/
.filter-button {
  font-size: 1.01vw;
letter-spacing: 0.175vw;
  border: 0px solid transparent;
  border-radius: 0px;
  text-align: center;
  color: #000;
padding: 0px 1.2vw;
  margin: 0 1.2vw 3.4vh;
background-color: transparent;
position: relative;
}
.filter-button:hover {
  border: 0px solid transparent;
  border-radius: 0px;
  text-align: center;
  color: #4B6DB4;
}
.filter-button.active {
  color: #4B6DB4;
}
.filter-button.active:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background-color: #4B6DB4;
}
.gallery_product {
  text-align: center;
  }
a.gallery_product-link-txt {
  margin-top: 10px;
  display: inline-block;
	color:#28A9E2;
  text-decoration: none;
}
a.gallery_product-link-txt:hover {
	color:#000;
}
.gallery_product img {
border-radius: 0.78vw;
border: 1px solid #4B6DB4;
}
.gallery_product img:hover {
border-radius: 0.78vw;
box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
-webkit-box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
-moz-box-shadow: 1vw 1vw 1vw 1vw rgba(0,0,0,0.1);
}
/* #logo-carousel .owl-carousel .owl-item img {
width: 6vw;
height: 10vh;
} */

/* #Media
=========== */
@media (max-width: 767px) {
.filter-button {
  font-size: 4.2vw;
  letter-spacing: 0.275vw;
  padding: 0px 2.2vw;
  margin: 0 3.2vw 2.4vh;
}
.gallery_product img {
  border-radius: 2.604vw;
}
.gallery_product img:hover {
  border-radius: 2.604vw;
  padding: 10vw 7vw 7vw;
  box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -webkit-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
  -moz-box-shadow: 6vw 6vw 6vw 6vw rgba(0,0,0,0.1);
}
/* #logo-carousel .owl-carousel .owl-item img {
  width: 18.135vw;
  height: 18.135vw;
} */
}













/*============== Footer CSS ======================*/
.footer-top {
/*background-image: linear-gradient(180deg,#fff 14%,#4B6DB4 14%);*/
background-image: linear-gradient(180deg,#fff 14%,#28A9E2 14%);
}
.bot-contact-form-inner {
background: url(assets/dist/images/businessman.png) no-repeat bottom left;
min-height: 600px;
}
.bot-contact-form-wrap {
margin-top: 16vh;
}

.footer-middle {
padding: 4em 0 2em;
background-color: #09092D;
}
.footer-middle h5 {
font-family: 'Helvetica';
font-weight: normal;
font-style: normal;
letter-spacing: 0.14vw;
  font-size: 1.35vw;
  line-height: 2.4vh;
}
.footer-middle p {
color: #fff;

  line-height: 22px;
letter-spacing: 0.046w;
}
.footer-middle a {
  color: #fff;
text-decoration: none;

  line-height: 2.5vh;
letter-spacing: 0.046w;
}
.footer-middle a:hover {
  color: #707070;
}
.footer-middle a i {
  font-size: 1.87vw;
}
.footer-bottom {
  background-color: #28A9E2;
}
.footer-bottom p {
color: #F2F2F4;
}


/* #Media
=========== */

@media (max-width: 767px) {
.bot-contact-form {
  /*background: #4B6DB4 url(assets/dist/images/mobile-contact-bg.png) no-repeat bottom left;*/
  background: #28A9E2 url(assets/dist/images/businessman.png) no-repeat bottom left;
}
.bot-contact-form-inner {
  background: transparent;
  min-height: auto;
}
.bot-contact-form-wrap {
  margin-top: 24%;
  min-height: 510px;
}
.footer-middle .col {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.footer-middle h5 {
  letter-spacing: 0.521vw;
  font-size: 4.948vw;
  line-height: 5.469vw;
}
.footer-middle p {
  font-size: 4.148vw;
  line-height: 5.469vw;
  letter-spacing: 0.260vw;
}
.footer-middle a {
  font-size: 4.148vw;
  line-height: 5.469vw;
  letter-spacing: 0.260vw;
}
.footer-middle a i {
  font-size: 4.848vw;
}
.bot-contact-form-wrap .subtitle {
  font-size: 3.62vw;
  margin-bottom: 1.68vw;
}
.form-control {
  background-color: rgba(255,255,255,0.5);
}

}
